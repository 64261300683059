<template>
  <div>
    <!-- <div>
      <img src="../../assets/img/dyBanner.png" style="display:block;width:100%;">
    </div> -->
    <section class="industryNews" v-html="industryData"></section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      industryData: ""
    };
  },

  methods: {
    getIndustryNews: function() {
      let that = this;
      let id = this.$route.query.id;
      that.$http(that.$api.selectIndustryNewsById+'?industry_news_id='+id , {})
        .then(res => {
          console.log(res)
          that.industryData = res.data.object.industryNewsContent;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted: function() {
    //调取本地的get(就在下面)
    this.getIndustryNews();
  }
};
</script>

<style>
.industryNews {
  display: block;
  width: 1000px;
  margin: 90px auto;
  animation: fadeInUp 1s ease 0s backwards;
  -webkit-animation: fadeInUp 1s ease 0s backwards;
}
.industryNews img {
  display: block;
  width: 80% !important;
  margin: 0 auto!important;
}
</style>